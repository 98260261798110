import Link from "./Link";

function Links(props) {
  if (props.data === undefined) {
    return;
  }
  const { data } = props.data;
  const list = [];
  data.forEach((element, index) => {
    const item = element.attributes;
    let icon = item.icon.data;
    let iconUrl = "logo.svg";
    if (icon !== null) {
      iconUrl = item.icon.data.attributes.url;
    }
    console.log(iconUrl);
    list.push(
      <div className="items-container" key={index}>
        <Link
          image={"http://links.manbatcave.com" + iconUrl}
          link={item.url}
          description={item.description}
          text={item.name}
        />
      </div>
    );
  });
  return list;
}

export default Links;
