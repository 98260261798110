import Links from "./components/Links";
import linker from "./api/linker";
import useSWR from "swr";

const fetcher = (url) => linker.get(url).then((res) => res.data);

function App() {
  const { data } = useSWR("/links?populate=*", fetcher);
  return (
    <div>
      <header></header>

      <title>Usefull Links</title>
      <link rel="icon" href="/favicon.ico" />
      <div className="flex flex-col items-center justify-center mt-5">
        {/* <img
          width={300}
          height={300}
          className="mx-auto rounded-full"
          src={profilePic}
          alt="Play With Abby"
          // layout={'fill'} objectFit={'contain'}
        /> */}
        <h2 className="my-3 text-3xl font-bold text-center text-indigo-900 md:text-4xl">
          Usefull Links
        </h2>

        <Links data={data}> </Links>
      </div>
    </div>
  );
}

export default App;
