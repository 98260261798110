import { Tooltip } from "flowbite-react";

function Link(props) {
  return (
    <Tooltip content={props.description}>
      <div className="wishlistitem">
        <a
          href={props.link}
          rel="noreferrer"
          target="_blank"
          className="wishlistitem w-full max-w-md cursor-pointer p-3 my-3 mx-auto rounded-md ring-[1px] ring-blue-300 backdrop-filter backdrop-blur-2xl bg-white bg-opacity-25 shadow-xl transform hover:-translate-y-1 hover:scale-110 transition duration-200 ease-in-out"
        >
          <img src={props.image} alt={props.text} className="item-image" />
          <h2 className="item-title ml-3 text-xl font-semibold md:ml-6">
            {props.text}
          </h2>
        </a>
      </div>
    </Tooltip>
  );
}
// }

export default Link;
