import axios from "axios";
const instance = axios.create({
  baseURL: "http://links.manbatcave.com/api/",
  timeout: 1000,
  headers: {
    Authorization:
      "Bearer 5331f11db3b14bc04a3be69be8d07547a03bf7f24c267bcb565c4a4323b3c6dd1b2fcd37c8f01dd337b93502202052a788060e1d5876f3e6fdfff2bd4e88f2aad2fa53902a7ba057979e5e0eb228518768858e81d4e58512a3584b20e4b8c38aff45ca1d9ab46dc66dad8666f7303543a51631876da73602061853a7801c083b",
  },
});

export default instance;
